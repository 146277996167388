<script setup>
import { useTabs } from "./useTabs";

const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

const tabsGroup = reactive(useTabs());

const sliderRef = ref(null);
const controlEl = ref(null);
const cssSliderWidth = ref(0);
const cssSliderX = ref(0);

provide("tabsGroup", tabsGroup);

watchEffect(() => setValue(props.modelValue));
watch(
  () => tabsGroup.tabsColl,
  () => {
    updateSliderPosition();
    updateValue();
  },
  { deep: true }
);

function updateSliderPosition() {
  function runAsync() {
    const tabEl = controlEl.value.querySelector(`.v-tab-label.active`);
    if (!tabEl) return;

    cssSliderWidth.value = toPixels(tabEl.clientWidth);
    cssSliderX.value = toPixels(tabEl.offsetLeft);
  }

  setTimeout(() => runAsync(), 10);
}

function setValue(value) {
  tabsGroup.activate(value);
  updateSliderPosition();
}

function updateValue() {
  const tabEl = tabsGroup.tabsColl.find((t) => t.value.isTabActive);
  if (tabEl?.value) emit("update:modelValue", tabEl.value.tabValue);
}
</script>
<template>
  <div class="v-tabs-labels-el" :ref="el => controlEl = el">
    <div class="tabs">
      <slot></slot>
      <span class="slider" ref="sliderRef"></span>
    </div>
  </div>
</template>
<style scoped>
.tabs {
  display: flex;
  position: relative;
  background-color: var(--background);
  box-shadow: 0 0 1px 0 rgba(#185ee0, 0.15), 0 6px 12px 0 rgba(#185ee0, 0.15);
  /* border-radius: 99px; */
}
.tabs * {
  z-index: 2;
}

.slider {
  position: absolute;
  display: flex;
  height: 3px;
  bottom: -4px;
  width: v-bind(cssSliderWidth);
  /* background-color: red; */
  /* var(--basis-color); */

  z-index: 1;
  border-radius: 99px;
  transition: 0.2s ease-out;
  transform: translate(v-bind(cssSliderX));
}
</style>
